module services {
    export module master {
        export class unitOfMeasureService implements interfaces.master.IUnitOfMeasureService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }
            //Gets list of Unit of Measures for a dropdown/autocomplete list
            getUoMDropdown(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "UnitOfMeasure/GetDropdownList", {
                        ownerEntityId: '@ownerEntityId'
                    }, {
                            query: {
                                method: 'GET',
                                isArray: true
                            }
                        });
            }
        }
    }
    angular.module("app").service("unitOfMeasureService", services.master.unitOfMeasureService);
}